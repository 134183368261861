<template>
  <div>
    <div>
      <div class="">
        <ValidationObserver ref="formCreateUnit">
          <form class="flex flex-wrap">
            <div class="w-full px-2" v-if="customer">
              <div class="clearfix">
                <button class="btn-blue-outline float-right" @click.prevent="autofillCustomerData()">{{ $t("new_department.copy_customer") }}<BaseIcon icon="edit" class="ml-2" /></button>
              </div>
            </div>

            <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="customer_name" type="text" :field_name="$t('new_department.customer')" disabled readonly />
            </div>

            <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>

            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="name" type="text" :field_name="$t('new_department.business_name')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="operation_manager" type="text" :field_name="$t('new_department.business_manager')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="company_number" type="text" :field_name="$t('new_department.org_number')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="contact_name" type="text" :field_name="$t('new_department.contact_person')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="contact_email" type="text" :field_name="$t('new_department.email')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="contact_phone" type="text" :field_name="$t('new_department.phone')" rules="required" />
            </div>

            <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>

            <div class="w-full text-sm font-bold px-2 pb-3">
              {{ $t("new_department.postal_address") }}
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="address" type="text" :field_name="$t('new_department.address')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="zip_code" type="text" :field_name="$t('new_department.zip_code')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="city" type="text" :field_name="$t('new_department.city_town')" rules="required" />
            </div>
            <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>
            <div class="w-full text-sm font-bold px-2 pb-3">
              {{ $t("department_billing_address.billing_address") }}
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="billing_address" type="text" :field_name="$t('department_billing_address.address')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="billing_zip_code" type="text" :field_name="$t('department_billing_address.zip_code')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="billing_city" type="text" :field_name="$t('department_billing_address.city_town')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="billing_reference" type="text" :field_name="$t('department_billing_address.ref')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="billing_invoice" type="text" :field_name="$t('department_billing_address.e_invoice')" rules="required" />
            </div>

            <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseSelect v-model="alarm_central" :options="alarm_centrals" nameSelector="name" optionSelector="_id" :field_name="$t('new_department.alarm_center')" rules="required" :disabled="user.role != 'admin' && user.role != 'seller'" :readonly="user.role != 'admin' && user.role != 'seller'" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseSelect v-model="seller_id" :options="sellers" optionSelector="_id" nameSelector="name" :field_name="$t('new_department.seller')" rules="required" :disabled="user.role != 'admin' && user.role != 'seller'" :readonly="user.role != 'admin' && user.role != 'seller'" />
            </div>

            <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>

            <div class="w-full text-sm font-bold px-2 pb-3">
              {{ $t("new_department.additional_services") }}
            </div>
            <div class="w-full">
              <template v-for="s in unitSettingOptions">
                <div class="w-full md:w-1/2 px-2 py-2" v-bind:key="s.id">
                  <label class="inline-flex items-center mt-3">
                    <input v-model="unitSettings" :value="s.id" type="checkbox" class="form-checkbox h-4 w-4" />
                    <span class="ml-2 text-gray-600 text-sm font-serif font-bold">{{ s.name }}</span>
                  </label>
                </div>
              </template>
            </div>

            <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>

            <div class="w-full px-2">
              <div class="clearfix">
                <button class="btn-blue float-right text-lg py-2 px-5" type="submit" @click.prevent="createUnit()">
                  {{ $t("user_actions.save") }}
                  <BaseIcon icon="arrow-right" class="ml-2" />
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title() {
    return this.$t("page_titles.create_new_department");
  },
  data() {
    return {
      customer_id: this.$route.params.customer_id,
      alarm_centrals: null,
      sellers: [],
      customer: null,
      name: "",
      address: "",
      zip_code: "",
      city: "",
      billing_address: "",
      billing_zip_code: "",
      billing_city: "",
      billing_reference: "",
      billing_invoice: "",
      company_number: "",
      operation_manager: "",
      contact_name: "",
      contact_email: "",
      contact_phone: "",
      customer_units: null,
      customer_name: "",
      seller_id: "",
      alarm_central: "",
      unitSettings: [],
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    unitSettingOptions() {
      return [{ id: "discreet_internal_alert", name: this.$t("new_department.discreet_internal_alert") }];
    },
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.create_new_department");
    },
  },

  methods: {
    getCustomer() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/customer/${this.customer_id}`)
        .then((response) => {
          this.customer = response.data.customer;
          this.customer_units = response.data.units;
          this.customer_name = this.customer.name;
          this.alarm_central = this.customer.alarm_central;

          this.setPageTitle(`${this.customer.name} - Skapa ny verksamhet`, "create_unit_admin");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getAlarmCentrals() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central/`)
        .then((response) => {
          this.alarm_centrals = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getSellersResellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/seller/reseller`)
        .then((response) => {
          this.sellers = response.data.data;
          if (this.user.role == "reseller" && this.user.reseller_id) {
            this.seller_id = this.user.reseller_id;
          } else if (this.user.role != "admin" && this.user.role != "seller") {
            let defaultSeller = _.find(this.sellers, { name: "Calle Baumgarten" });
            if (defaultSeller) this.seller_id = defaultSeller._id;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    createUnit() {
      this.$refs.formCreateUnit.validate().then((success) => {
        if (!success) {
          return;
        }

        let data = {
          parent_id: this.customer._id,
          name: this.name,
          contact_name: this.contact_name,
          contact_email: this.contact_email,
          contact_phone: this.contact_phone,
          company_number: this.company_number,
          operation_manager: this.operation_manager,
          address: this.address,
          zip_code: this.zip_code,
          city: this.city,
          billing_address: this.billing_address,
          billing_zip_code: this.billing_zip_code,
          billing_city: this.billing_city,
          billing_reference: this.billing_reference,
          billing_invoice: this.billing_invoice,
          alarm_central: this.alarm_central,
          seller_id: this.seller_id,
          unitSettings: this.unitSettings,
        };

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/units/`, data)
          .then(async () => {
            // eslint-disable-line
            // allow access to any new unit created by 'user' role
            if (this.user.role == "user") await this.getUser();

            if (this.user.role == "reseller") this.$router.push({ name: "units", params: { type: "reseller", customer_id: this.customer_id, id: this.user.reseller_id } });
            else this.$router.push({ name: "units", params: { type: "customer", customer_id: this.customer_id, id: "-" } });
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    autofillCustomerData() {
      this.company_number = this.customer.company_number;
      this.contact_name = this.customer.contact_name;
      this.contact_email = this.customer.email;
      this.contact_phone = this.customer.phone;
      this.address = this.customer.address;
      this.zip_code = this.customer.zip_code;
      this.city = this.customer.city;
      this.billing_address = this.customer.billing_address;
      this.billing_zip_code = this.customer.billing_zip_code;
      this.billing_city = this.customer.billing_city;
      this.billing_reference = this.customer.billing_reference;
      this.billing_invoice = this.customer.billing_invoice;

      this.handleSuccess("Kundinformation kopierats");
    },
  },

  created() {
    this.getCustomer();
    this.getAlarmCentrals();
    this.getSellersResellers();
  },
};
</script>
